import { defineConfig } from "../config";
import { authHeader } from "../helpers";
import { utilsService } from "./utils";
const { handleResponse } = utilsService;

export const circuitsService = {
  getApiPath,
  deleteCircuit,
  getCircuitById,
  getCircuitsByIdSystem,
  editCircuit,
  getAllAssetByCircuit,
  getAllCircuitsSystems,
  createCircuit,
};

const { apiUrl, apiVer } = defineConfig.CIRCUITS_SERVICE;

function getApiPath() {
  const version = apiVer !== null ? "/" + apiVer : "";
  return apiUrl + version;
}

function getAllCircuitsSystems(type) {
  const url = getApiPath() + "/circuit/byType?type=" + type;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function createCircuit(assetsId = null, values) {
  let url =
    getApiPath() +
    "/circuit/create?name=" +
    values.name +
    "&type=" +
    values.type;

  if (values.playlistId) {
    url = url + "&playlistId=" + values.playlistId;
  }
  if (values.showId) {
    url = url + "&showId=" + values.showId;
  }

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    ...(assetsId && { body: JSON.stringify(assetsId) }),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function editCircuit(idCircuit, assetsId = null, values) {
  let url =
    getApiPath() +
    "/circuit/update?id=" +
    idCircuit +
    "&name=" +
    values.name +
    "&type=" +
    values.type;

  const requestOptions = {
    method: "PATCH",
    headers: authHeader("application/json"),
    ...(assetsId && { body: JSON.stringify(assetsId) }),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function deleteCircuit(circuitId) {
  const url = getApiPath() + "/circuit/delete?id=" + circuitId;

  const requestOptions = {
    method: "DELETE",
    headers: authHeader("application/json"),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function getCircuitById(id, type) {
  const url = getApiPath() + "/circuit/id?id=" + id + "&type=" + type;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function getCircuitsByIdSystem(idSystem) {
  const url =
    getApiPath() + "/circuit/byAssetId?assetId=" + idSystem;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function getAllAssetByCircuit(idCircuit, type) {
  const url =
    getApiPath() +
    "/circuit/assetsByCircuitId?id=" +
    idCircuit +
    "&type=" +
    type;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
