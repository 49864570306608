import { authHeader } from "../helpers";
import { gatewayConfig } from "../config/gateway";

export const userService = {
  login,
  profile,
  logout,
  setInStorage,
  sendMailCodeReset,
  savePassword,
  newPassword,
  registerUser,
  createUser,
  uploadAvatar,
  getAvatar,
  readUser,
  readUserAll,
  getAllRole,
  updateUser,
  deleteUser,
  getColorByType,
  getLabelByType,
  getLocalUserType,
  loginGuest,
};

const { apiUrl } = gatewayConfig.USER_SERVICE("dev");

function getApiPath() {
  return apiUrl;
}

function login(username, password) {
  let formData = new FormData();
  formData.append("username", username);
  formData.append("password", password);
  formData.append("grant_type", "password");

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: "Basic " + window.btoa("browser:1234"),
    },
    body: formData,
  };

  return fetch(apiUrl + "/oauth/token", requestOptions)
    .then(handleResponse)
    .then((response) => {
      // login successful if there's a user in the response
      if (response) {
        console.log(response);
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes
        const auth = response.token_type + " " + response.access_token;
        const refresh = response.refresh_token;
        localStorage.setItem("auth", auth);
        localStorage.setItem("refresh", refresh);
        return profile(auth).then(
          (user) => {
            const resUser = this.setInStorage(user);
            return resUser;
          },
          (error) => {
            console.log(error);
            return error;
          }
        );
      }
    });
}

function setInStorage(user) {
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem(
    "type",
    (user.authorities && user.authorities[0]) || ["ROLE_ADMIN"]
  );
  localStorage.setItem("group", user.group || "administrators");
  localStorage.setItem("subscription", JSON.stringify(user.subscription));
  localStorage.setItem("check", 1);
  return user;
}

function profile(auth) {
  let header = {
    Authorization: auth,
  };
  const requestOptions = {
    method: "GET",
    headers: header,
  };

  return fetch(apiUrl + "/user/profile", requestOptions).then(handleResponse);
}

function sendMailCodeReset(username) {
  const requestOptions = {
    method: "POST",
    headers: { "content-type": "application/json" },
  };
  return fetch(
    apiUrl + "/user/forgotPassword?username=" + username,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  localStorage.removeItem("type");
  localStorage.removeItem("group");
  localStorage.removeItem("auth");
  localStorage.removeItem("subscription");
  localStorage.removeItem("check");
}

function registerUser(user) {
  const url = apiUrl + "/prereg/signup";
  const body = JSON.stringify(user);

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: body,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function savePassword(passwordToken) {
  const requestOptions = {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(passwordToken),
  };
  return fetch(apiUrl + "/user/savePassword", requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function newPassword(oldPassword, newPassword) {
  const url = getApiPath() + "/user/new-password";
  const body = JSON.stringify({
    oldPassword,
    newPassword,
    newPasswordConfirm: newPassword,
  });

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function createUser(user) {
  const url = getApiPath() + `/user/createUser`;
  const body = JSON.stringify(user);

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: body,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function uploadAvatar (formData) {
  const url = getApiPath() + `/user/uploadAvatar`;

  const requestOptions = {
    method: "POST",
    headers:{Authorization: authHeader().Authorization},
    body: formData
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function getAvatar(idAvatar) {
  const url = getApiPath() + `/user/getAvatar/${idAvatar}`;

  const requestOptions = {
    method: "GET",
    headers:{Authorization: authHeader().Authorization},
  };

  return fetch(url, requestOptions)
    .then(handleResponseBlob)
    .then((res) => {
      return res;
    });
}

function readUser(id) {
  const url = getApiPath() + `/user/getUserById/${id}`;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function readUserAll(page, perPage, sortType) {
  const url =
    getApiPath() + `/user/all/page/${page}/perPage/${perPage}/sort/${sortType}`;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}


function getAllRole () {
  const url =
    getApiPath() + `/user/getAllRole`;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function updateUser(body) {
  const url = getApiPath() + `/user/updateUser?id=${body.id}`;

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(body),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function deleteUser(id) {
  const url = getApiPath() + `/user/deleteUser?userId=${id}`;

  const requestOptions = {
    method: "DELETE",
    headers: authHeader("application/json"),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function getTypesList() {
  return [
    {
      label: "Super Admin",
      value: "ROLE_ADMIN",
      desc: "All permission",
      color: "#e94445",
    },
    {
      label: "Manager",
      value: "ROLE_MANAGER",
      desc: "Manage all company user",
      color: "orange",
    },
    {
      label: "Guest User",
      value: "ROLE_GUEST",
      desc: "Publisher rules",
      color: "cyan",
      // sidebar: true
    },
  ];
}

function getColorByType(type) {
  const types = getTypesList();
  let objType = types.filter((t) => type === t.value);
  const color = objType.length === 1 ? objType[0].color : null;
  return color;
}

function getLabelByType(type) {
  const types = getTypesList();
  let objType = types.filter((t) => type === t.value);
  const label = objType.length === 1 ? objType[0].label : null;
  return label;
}

function getLocalUserType() {
  return localStorage.getItem("type") || null;
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function handleResponseBlob(response) {
  return response.blob().then(blob => {
      return blob;
    }
  );
}


function loginGuest(accessToken, guestToken) {
  const auth = "Bearer " + accessToken
  localStorage.setItem("auth", auth);
  localStorage.setItem("guest", guestToken);

  return userService.profile(auth).then(
    user => {
      const resUser = this.setInStorage(user)
      return resUser
    },
    error => {
      console.log(error);
      return error;
    }
  );
}
