// return authorization header with basic auth credentials
export function authHeader(contentType = null) {
  let user = JSON.parse(localStorage.getItem("user"));
  let typeUser = localStorage.getItem("type");
  let auth = localStorage.getItem("auth");
  // let groupUser = localStorage.getItem("group") ? localStorage.getItem("group").toUpperCase() : "";
  let check = localStorage.getItem("check") || 0;

  // if (user && user.access_token) {
  if (user && typeUser && auth && check) {

    let header = {};

    if(!contentType){
      header = {
        'authorization': auth,
        // 'X_user': user.username,
      };
    }
    else{
      header = {
        'Authorization': auth,
        'Content-Type': contentType,
        'username': user?.principal?.username
      };
    }

    return header;

  } else {
    console.log("User or password wrong")
    window.location.replace("/logout?error=wronguser");
  }
}
