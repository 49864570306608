import FileSaver from "file-saver";
import { defineConfig } from "../config";
import { authHeader } from "../helpers";
import { utilsService } from "./utils";
const { handleResponse } = utilsService;

export const productService = {
  getAll,
  getById,
  deleteById,
  upsert,
  uploadProductPhoto,
  getProductPhotoById,
  deleteProductPhoto,
};

const { apiUrl } = defineConfig.PRODUCT_SERVICE;

function getAll(catalogId = "") {
  const url = apiUrl + `/estimate/v1/product/all?catalogId=${catalogId}`;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function getById(catalogId = "") {
  const url = apiUrl + `/estimate/v1/product/${catalogId}`;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

// function getCommission(catalogId = "") {
//   const url = apiUrl + `/estimate/v1/product/${catalogId}`;

//   const requestOptions = {
//     method: "GET",
//     headers: authHeader("application/json"),
//   };

//   return fetch(url, requestOptions)
//     .then(handleResponse)
//     .then((res) => {
//       return res;
//     });
// }

function deleteById(productId) {
  const url = apiUrl + `/estimate/v1/product/delete/?productId=${productId}`;

  const requestOptions = {
    method: "DELETE",
    headers: authHeader("application/json")
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function upsert(product, catalogIds) {
  let params = new URLSearchParams();
  console.log('catalogIds', catalogIds)
  catalogIds.forEach((item, index) => {
    params.append(`catalogIds`, item);
  });

  const url = apiUrl + `/estimate/v1/product/save/?${params.toString()}`;

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(product)
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function uploadProductPhoto(formData) {
  const url = apiUrl + `/v1/productPhoto/uploadProductPhoto`;

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getProductPhotoById(id) {
  return apiUrl + "/v1/productPhoto/getProductPhoto/" + id;
}

function deleteProductPhoto(id, productId) {
  const url = apiUrl + `/v1/productPhoto/delete/` + id + `?productId=` + productId;

  const requestOptions = {
    method: "DELETE",
    headers: authHeader("application/json")
  };
  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}
