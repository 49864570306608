import FileSaver from "file-saver";
import { defineConfig } from "../config";
import { authHeader } from "../helpers";
import { utilsService } from "./utils";
const { handleResponse } = utilsService;

export const catalogService = {
  getAllDto,
  getAll,
  getById,
  getCatalogsByProductId,
  create,
  updateById,
  deleteById,
};

const { apiUrl } = defineConfig.CATALOG_SERVICE;

function getAllDto() {
  const url = apiUrl + "/getAllDto/";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function getAll() {
  const url = apiUrl + "/getAll/";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function getById(catalogId) {
  const url = apiUrl + `/getById/${catalogId}`;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function create(catalog) {
  const url = apiUrl + `/save`;

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(catalog)
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function updateById(catalogId, catalog) {
  const url = apiUrl + `/update/${catalogId}`;

  const requestOptions = {
    method: "PUT",
    headers: authHeader("application/json"),
    body: JSON.stringify(catalog)
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function deleteById(catalogId) {
  const url = apiUrl + `/delete/${catalogId}`;

  const requestOptions = {
    method: "DELETE",
    headers: authHeader("application/json")
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function getCatalogsByProductId(productId) {
  const url = apiUrl + `/getCatalogsByProductId/${productId}`;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json")
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
