import {defineConfig} from '../config'
import { authHeader } from '../helpers';
import { utilsService } from './utils'
const {handleResponse} = utilsService

export const companiesService = {
    getApiPath,
    getAll,
};

const {apiUrl, apiVer} = defineConfig.COMPANIES_SERVICE;

function getApiPath(){
  const version = (apiVer !== null ? ("/" + apiVer) : "");
  return (apiUrl + version);
}


function getAll() {
    const url = getApiPath() + "/company/getAll";
  
    const requestOptions = {
      method: "GET",
      headers: authHeader("application/json"),
    };
  
    return fetch(
      url,
      requestOptions
    )
      .then(handleResponse)
      .then(res => {
        return res;
      });
}