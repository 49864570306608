import FileSaver from 'file-saver';
import {defineConfig} from '../config'
import { authHeader } from '../helpers';
import { utilsService } from './utils'
const {handleResponse} = utilsService

export const systemsService = {
    getApiPath,
    getAllSystems,
    getAllSystemsByType,
    getAllTypeSystems,
    getAllSizeSystems,
    getAllSystemsByArea,
    getAllSystemsPaginatedByArea,
    getAllSystemsDoohByArea,
    getAllSystemsUnassociatedByType,
    getAllMediaOwners,
    getAllAreaSystems,
    createSystem,
    getSizeById,
    getSystemById,
    getSystemsByIds,
    downloadSystemTemplate,
    updateSystem,
    deleteSystem,
    importSystems,
    deleteDomainsSystem,
    getPreviewId,
    getAllLease,
    getAllAuthorizations,
    getLocationByAddress
};

const {apiUrl, apiVer} = defineConfig.SYSTEMS_SERVICE;

function getApiPath(){
  const version = (apiVer !== null ? ("/" + apiVer) : "");
  return (apiUrl + version);
}

function getAllSystems() {
  const url = getApiPath() + "/system/all/";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAllSystemsByType(type) {
  const url = getApiPath() + "/system/all/" + type;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAllTypeSystems(type) {
  const url = getApiPath() + "/system/type/all" + "?type=" + type;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAllMediaOwners() {
  const url = getApiPath() + "/mediaOwner/all";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAllSizeSystems(type) {
  const url = getApiPath() + "/size/all?type=" + type;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}
function getSystemsByIds(ids){
  const url = getApiPath() + "/system/getAllByIds";
  
  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(ids)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}


function getAllSystemsByArea(cid, type) {
  const url = getApiPath() + "/system/area/list?cid=" + cid + "&type=" + type;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAllSystemsPaginatedByArea( page = 0, perPage = 10, searchTerm = "", type = "") {
  let url = "";
  if(searchTerm == null){
    url = getApiPath() + "/system/all/page/" + page + "/perPage/" + perPage + "?type=" + type + "&searchTerm=" + searchTerm ;
  }
  else{
    url = getApiPath() + "/system/all/page/" + page + "/perPage/" + perPage + "?type=" + type ;
  }

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}
function getAllSystemsDoohByArea() {
  const url = getApiPath() + "/system/all/?type=DOOH";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(res => {
      console.log("Contenuto della risposta:", res);
      return res;
    });
}
function getAllSystemsUnassociatedByType(type = "OOH") {
  const url = getApiPath() + "/system/area/listDto/all?noCircuit=true&type=" + type;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(res => {
      console.log("Contenuto della risposta:", res);
      return res;
    });
}

function getAllAreaSystems() {
  const url = getApiPath() + "/system/area/all";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getSystemById(id, type) {
  const url = getApiPath() +"/system/id/"+ id + "?type="+ type;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function updateSystem(system, type) {
  const url = getApiPath() + "/system/update?id=" + system.id + "&type=" + type;

  const requestOptions = {
    method: "PATCH",
    headers: authHeader("application/json"),
    body: JSON.stringify(system)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}
function createSystem(body, type) {
  const url = getApiPath() + "/system/create/?type=" + type;

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(body)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function deleteSystem(systemId, type) {
  const url = getApiPath() + "/system/delete?id=" + systemId + "&type=" + type;

  const requestOptions = {
    method: "DELETE",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function importSystems(file) {
  const url = getApiPath() + "/system/upload";
  const formData = new FormData();
  formData.append("file", file);

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData
  };

  return fetch(
    url,
    requestOptions
  )
  .then(handleResponse)
  .then(res => {
    return res;
  });
}

function downloadSystemTemplate() {
  const url = getApiPath() + "/system/download/templateImportSystems";

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    url,
    requestOptions
  )
  .then((response) => response.blob())
  .then((blob) => FileSaver.saveAs(blob, 'template_impianti.xlsx'))
}


function deleteDomainsSystem(systemId, domains, type) {
  const url = getApiPath() + "/system/" + systemId + "/domains" + type;

  const requestOptions = {
    method: "DELETE",
    headers: authHeader("application/json"),
    body: JSON.stringify({domains: domains})
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}


function getSizeById(idSize, type) {
  const url = getApiPath() +"/size/byId?sid="+ idSize + "&type=" + type;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}



function getPreviewId(type){
  const url = getApiPath() +"/system/getPreviewId?sequenceName=" + type;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAllLease(){
  const url = getApiPath() +"/system/lease/all";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAllAuthorizations(){
  const url = getApiPath() +"/system/authorization/all";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getLocationByAddress(address){
  const url = getApiPath() +"/geocode/getGeocodeLocation?address=" + address;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}