import {defineConfig} from '../config'
import { authHeader } from '../helpers';
import { utilsService } from './utils'
const {handleResponse} = utilsService

export const geoService = {
    getApiPath,
    getAddressByLatLong,
    getLatLongByAddress,
};

const {apiUrl, apiVer} = defineConfig.GEO_SERVICE;

function getApiPath(){
  const version = (apiVer !== null ? ("/" + apiVer) : "");
  return (apiUrl + version);
}

function getAddressByLatLong(lat, lng) {
  const url = getApiPath() + "/geocode/getAddressByLatLong?latitude="+ lat +"&longitude=" + lng;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getLatLongByAddress(address) {
  const url = getApiPath() + "/geocode/getLatLongByAddress?address=" + address;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}
