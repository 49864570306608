import React from "react";
import { Route, Redirect } from "react-router-dom";

export const PrivateRoute = ({ component: Component, render: Render, isAllowed, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      (localStorage.getItem("user") && localStorage.getItem("type")) ?
      (
        isAllowed ? (Render ? <Render {...props} /> : <Component {...props} />)
        : (
          <Redirect
            to={{ pathname: "/403", state: { from: props.location } }}
          />
        )
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )
    }
  />
);