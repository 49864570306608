import React from 'react';
import Lottie from "lottie-react";
import animationData from './alessiplatform-loader.json';
// import {defineConfig} from '../../config';
// const {primaryColor} = defineConfig;

function Loader(props) {
    const { fullscreen, simpleLoader, messages, className } = props
    if (fullscreen) {
        return (
            <div className={"row " + className} style={{ background: "#fff" }}>
                <div className="col text-center w-100 vh-100">
                    <div style={{ width: 200, borderRadius: "50%", margin: "auto", marginTop: "20vh", background: "#fff" }}>
                        <Lottie animationData={animationData} />
                    </div>
                </div>
            </div>
        )
    } else if (simpleLoader) {
        return (
            <div className={"row " + className} style={{display: 'flex', justifyContent: 'center'}}>
                <div className="col-auto">
                    <div className="text-center" style={{ width: "150px", borderRadius: "50%", background: "#fff" }}>
                        <Lottie animationData={animationData} />
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div className={"row " + className}>
                <div className="col-auto offset-2">
                    <div className="text-right" style={{ width: "150px", borderRadius: "50%", background: "#fff" }}>
                        <Lottie animationData={animationData} />
                    </div>
                </div>
                {messages && <div className="col align-self-center mt-4">Loading</div>}
            </div>
        )
    }
}

export default Loader;